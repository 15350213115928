import React ,{ useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import '../index.css'
import { styled, keyframes } from "@mui/system";
import moment from 'moment-jalaali'
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';

import Flashcard from './Flashcard';
import axios from 'axios';
import { TroubleshootRounded } from '@mui/icons-material';

function LeitnerApp(){
  const flashcards = [
    {
      id: 1,
      word: "Apple",
      options: ["سیب", "موز", "پرتقال", "گلابی"],
      answer: "سیب",
    },
    {
      id: 2,
      word: "Car",
      options: ["دوچرخه", "قطار", "ماشین", "هواپیما"],
      answer: "ماشین",
    },
    {
      id: 3,
      word: "Book",
      options: ["کتاب", "قلم", "کاغذ", "دفتر"],
      answer: "کتاب",
    },
    // می‌توانید کلمات بیشتری اضافه کنید
  ];
  
   // Define custom colors and gradient for the background
   const colors = {
    gradientBackground: "#212121", // Dark blue to soft purple
    primaryBox: "#212121", // Dark blue shade for the curved box
    button: "#ffe600", // Purple shade for the button
    buttonHover: "#ffe600", // Darker purple for hover effect
    textPrimary: "#EEEEEE", // White text color
  };
  const [date, setDate] = useState('Loading...')
  const [isNewUser, setNewUser] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [start, setStart] = useState(false)
  const [amount, setAmount] = useState(0)
  const [isEndAmount, setIsEndAmount] = useState(false)
  const [learnedCount, setLearned] = useState(0)
  const [unLearnedCount, setUnLearned] = useState(0)
  const [userId, setUserId] = useState(0)
  const [isError, setError] = useState(false)
  const [currentWord, setWord] = useState({
    id: '',
    word: '',
    options: [],
    answer: ''
  })


  const handleStart = async() => {
    await getNextWord()
    setStart(true)
  }

  const backToMain = () => {
    setStart(false)
  }
  const [currentCardIndex, setCurrentCardIndex] = useState(0)

  moment.loadPersian({ dialect: 'persian-modern', usePersianDigits: true })
  useEffect(() => {
    const tg = window.Telegram.WebApp
    tg.ready()
    tg.expand()
    const username = tg.initDataUnsafe?.user.id
    // console.log(username)
    const todayShamsi = moment().format('jD jMMMM jYYYY');
    console.log(todayShamsi)
    setDate(todayShamsi)
    // setUserId(username)
    
    const maxRetries = 5;  // Set a maximum number of retries
    let retryCount = 0
    const fetchUserId = async () => {
      const username = await tg.initDataUnsafe?.user?.id;

      if (username) {
        setUserId(username);
      } else if (retryCount < maxRetries) {
        console.warn('User ID not available, retrying...');
        retryCount = retryCount + 1;
        setTimeout(fetchUserId, 500);  // Retry after 500ms
      } else {
        setError(true)
        console.error('Failed to retrieve User ID after multiple attempts');
      }
    };

    fetchUserId();
    
    
    
    
    // UnComment lines bellow when just ready to deploy!
    
    
    
  }, [])
  

 

  useEffect(() => {
    // setLoading(true)
    if(userId != 0){
      axios.post('/web/getdata', { userId: userId })
      .then((res) => {
        if(res.data.message === 'ok'){
          setAmount(res.data.leitnerAmount)
          setLearned(res.data.learnedWord)
          setNewUser(false)
          setUnLearned(res.data.unLearnedWord)
          setLoading(false)
          
        }else if(res.data.message === 'notfound'){
          setLoading(false)
          setNewUser(true)
        }
        
      })
      .catch((err) => {
        setError(true)
        setLoading(false)
        console.error('Error fetching user data:', err);
      });

    }
  }, [userId])






  function getNextWord() {
    setLoading(true)
    axios.post('/web/nextword', { userId: userId })
      .then((res) => {
        setAmount(res.data.leitnerAmount)
        setWord({
          id: res.data.word.id,
          word: res.data.word.text,
          options: res.data.word.options,
          answer: res.data.word.correctMeaning
        })
        if(res.data.leitnerAmount <= 0){
          setIsEndAmount(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        setError(true)
        console.error('Error fetching user data:', err);
      })
  }
  const handleNext = async() => {
    await getNextWord()


    // if (currentCardIndex < flashcards.length - 1) {
    //   setCurrentCardIndex(currentCardIndex + 1);
    // } else {
    //   setStart(false);
    //   setCurrentCardIndex(0);
    //   // alert("آزمون تمام شد!");
    // }
  }

  const handleEnd = () => {
    setStart(false);
  }

  const handleRemove = () => {
    setLoading(true)
    axios.post('/web/remove', {userId: userId, word: currentWord.word, wordId: currentWord.id})
    .then(res => {

    })
    .catch(err => {

    })
  }
  
  // Glowing animation keyframes
  const shine = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;



  const GlowingButton = styled(Button)({
    background: "linear-gradient(90deg, #ffe600, #ffa217, #ffe600)",
    backgroundSize: "200% 200%",
    borderRadius: "20px",
    color: '#000',
    fontFamily: "Rubik",
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "bold",
    width: '100%',
    border: "1px solid black",
    boxShadow: " 0px 10px 50px -20px #efb021, 0px 30px 60px -30px #efb021",
    animation: `${shine} 2s linear infinite`,
    "&:hover": {
      background: "linear-gradient(90deg, #ffe600, #ffa217, #ffe600)",
    },
    
  });
  // Styled curved box for reports
  const ReportBox = styled(Box)({
    backgroundColor: colors.primaryBox,
    fontFamily: "Rubik",
    borderRadius: "20px",
    padding: "20px",
    marginBottom: "20px",
    color: colors.textPrimary,
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    border: "0.2px #303030 solid"
  });

  const Typographies = styled(Typography)({
    fontFamily: "Rubik"
  })

  // Styled curved button for starting learning
  const StartButton = styled(Button)({
    backgroundColor: colors.button,
    fontFamily: "Rubik",
    borderRadius: "30px",
    color: colors.textPrimary,
    padding: "10px 30px",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.buttonHover,
    },
  });







  if(isLoading) {
    return (
      <Box
      sx={{
        minHeight: "100vh",
        background: colors.gradientBackground,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }} >
        <CircularProgress />

      </Box>
    )
  }

  if(isError){
    return (
      <span style={{color: "white"}}>
        یک خطا رخ داده است
      </span>
    )
  }

  if(isNewUser){
    return (
      <Box
      sx={{
        minHeight: "100vh",
        background: colors.gradientBackground,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }} >
          <ReportBox>
          <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px' }}>
            <span> شما هنوز ربات وردینا رو استارت نزدید!</span>
            <p style={{ fontSize: '11px', color: '#838383' }}>          لطفا به ربات برگردید و دکمه Start رو بزنید. یک بسته کلمه انتخاب کنید و کلمات رو تمرین کنید. کلماتی که بلد نیستید رو به لایتنر اضافه کنید و به اینجا برگردید.
            </p>
          </Typographies>
          <p>{userId}</p>
        </ReportBox>

      </Box>
    )
  }

  return (

    

    <Box
      sx={{
        minHeight: "100vh",
        background: colors.gradientBackground,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }} >
      <div style={{ color: '#838383', display: 'flex', direction: 'rtl', marginBottom: '10px', fontSize: '14px', textAlign: 'left', width: '100%' }}>
        <span style={{ color: '#ffe600', boxShadow: " 0px 10px 50px -20px #efb021, 0px 30px 60px -30px #efb021" }}><OfflineBoltOutlinedIcon></OfflineBoltOutlinedIcon></span>
        <span style={{ width: '50%', color: '#ffe600', textAlign: 'right', padding: '4px' }}> اعتبار من: {amount}</span>
        <span style={{ width: '50%' }}>امروز {date}</span>
        <span>{userId}</span>
      </div>
      {!start ? (
        <span>
        <ReportBox>
          <Typographies variant="h5" sx={{ marginBottom: "10px", width: '320px' }}>
            <span style={{ fontWeight: 'bold', color:'#ffe600', textShadow: "#fdaf00 1px 0 10px"}}>جعبه لایتنر</span> وردینا اینجاست
            <p style={{ fontSize: '11px', color: '#838383'}}>اینجا کلمات رو انقدر تمرین می‌کنی تا حسابی تو ذهنت بمونن</p>
          </Typographies>
         
        </ReportBox>
        
        
        <ReportBox style={{ width: '320px'}}>
          <div style={{display: 'flex', direction: 'rtl', color: "#838383"}}>
            <span style={{width: '60%'}}>کلمه‌های جعبه‌ی من</span>
            <span style={{width: '40%', color: '#fff8e8', textShadow: "#fff8e8 1px 0 10px", fontSize: "18px"}}>{unLearnedCount}</span>
          </div>
        </ReportBox>
        
        <ReportBox style={{ width: '320px', color: "#838383"}}>
          <div style={{display: 'flex', direction: 'rtl'}}>
            <span style={{width: '60%'}}>کلمه‌هایی که یاد گرفتم</span>
            <span style={{width: '40%', color: '#fff8e8', textShadow: "rgb(0, 84, 0) 1px 0 10px", fontSize: "18px"}}>{learnedCount}</span>
          </div>
        </ReportBox>
        
        {/* Curved Button for Start Learning */ }
        { !isEndAmount ? (
          <GlowingButton variant="contained" onClick={handleStart}>
            شروع تمرین
          </GlowingButton>

        ): (
          
          <GlowingButton variant="contained" disabled='true'>
            اعتبار شما پایان یافت
          </GlowingButton>
        )}
        {/* <FooterMenu></FooterMenu> */}
        </span>


      ): (
          <>
            {!isEndAmount ? (

              <Flashcard
                // card={flashcards[currentCardIndex]}
                card={currentWord}
                handleNext={handleNext}
                handleEnd={handleEnd}
                handleRemove={handleRemove}
              />
            ) : (
              <span>
                  <ReportBox style={{ width: '320px', color: "#838383" }}>
                    <div style={{ display: 'flex', direction: 'rtl' }}>
                      <span style={{ width: '100%' }}>اعتبار شما پایان یافت!</span>

                    </div>
                  </ReportBox>
                  <GlowingButton variant="contained" onClick={backToMain}>
                     صفحه اصلی
                  </GlowingButton>
              </span>
            )}
          </>
        )}


        
        </Box>
  )

  
};

export default LeitnerApp;
